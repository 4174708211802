import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects"
import { toggleTeethAreShifting } from "../actions/adjustments"

import {
  setActiveAddition,
  setActiveCariesProId,
  setShowDrawingWarning,
} from "../actions/drawing"
import { setActiveFilter } from "../actions/imageControls"
import { setDataIsChanged } from "../actions/saving"
import { addUserAdditions } from "../actions/serverData"
import { setActiveTooth } from "../actions/teeth"
import { rememberState } from "../actions/withHistory"
import { ActiveFilter } from "../reducers/imageControlsReducer"
import { getTeethAreShifting } from "../selectors/adjustments"
import {
  getDrawingAction,
  getMasksToDraw,
  isDrawingModeActive,
} from "../selectors/drawing"
import {
  getKind,
  getActiveAddition,
  getAllAdditions,
  getTeethListForRightTeethMap,
} from "../selectors/serverData"
import { getActiveTooth } from "../selectors/teeth"
import {
  AnnotationName,
  AnnotationOnTooth,
  RestorationSubtype,
} from "../types/adjustmentTypes"
import { Detection } from "../types/dataStructureTypes"
import { DrawingAction, DrawingTypes } from "../types/drawing"
import TeethTypes from "../types/teethTypes"
import { disableAnnotationCombinationsBySubtype } from "./adjustmentsSaga"

function* setDrawingActionSaga() {
  const drawingAction: DrawingAction = yield select(getDrawingAction)

  if (drawingAction === DrawingAction.select) {
    return
  }

  const activeTooth: number = yield select(getActiveTooth)
  const annotationType =
    AnnotationName[drawingAction] || AnnotationName.restorations
  const masksToDraw: AnnotationOnTooth[] = yield select(getMasksToDraw)
  const teethList: Detection[] = yield select(getTeethListForRightTeethMap)
  const lastPeriDetection = Object.values(teethList).at(-1)

  yield put(setShowDrawingWarning(false))
  yield put(
    setActiveAddition({
      toothName:
        drawingAction === DrawingAction.annotate
          ? 0
          : activeTooth ||
            Number(lastPeriDetection?.toothName?.toString().charCodeAt(0)) + 1,
      type: annotationType,
      subtype: RestorationSubtype[drawingAction],
      id: masksToDraw[0]?.id,
      mask: masksToDraw[0]?.mask,
      ids: masksToDraw.flatMap((a) => a.id || []),
      ...(drawingAction === DrawingAction.calculus && !masksToDraw[0]?.location
        ? { location: "md" } // set default location for calculus, unless we already have a location
        : {}),
    })
  )
}

function* deactivateMutuallyExclusiveFeatures() {
  const drawingModeActive: boolean = yield select(isDrawingModeActive)
  yield put(setActiveFilter(ActiveFilter.NAVIGATION))
  const kind: string = yield select(getKind)

  if (!drawingModeActive) {
    yield put(setShowDrawingWarning(false))
    yield put(setActiveAddition(null))
    yield put(setActiveAddition(null))
    yield put(setActiveCariesProId(null))
    if (kind === "PERI") {
      yield put(setActiveTooth(null))
    }
  }

  const teethAreShifting: boolean = yield select(getTeethAreShifting)
  if (!teethAreShifting) return
  yield put(toggleTeethAreShifting())
}

function* saveDrawingAnnotationSaga() {
  const drawingModeActive: boolean = yield select(isDrawingModeActive)
  const activeAddition: AnnotationOnTooth = yield select(getActiveAddition)
  const kind: string = yield select(getKind)
  if (
    !drawingModeActive ||
    (kind !== "PERI" && !activeAddition?.id && !activeAddition?.mask)
  ) {
    return
  }

  const drawingAction: DrawingAction = yield select(getDrawingAction)
  const annotationType =
    AnnotationName[drawingAction] || AnnotationName.restorations
  const subtype = RestorationSubtype[drawingAction]
  const allAdditions: AnnotationOnTooth[] = yield select(getAllAdditions)

  // Return if the addition already exists with the same mask
  if (
    activeAddition.id &&
    allAdditions?.find(
      (a) => activeAddition.id === a.id && activeAddition.mask === a.mask
    )
  ) {
    return
  }

  yield put(rememberState())

  const extraIds = activeAddition.ids?.slice(1) || []
  const newAdditions = [
    { ...activeAddition, ids: undefined },
    ...allAdditions
      .filter((a) => a.id && a.mask && extraIds.includes(a.id))
      .map((a) => ({ ...a, mask: undefined })), // remove mask from all additions except the first
  ]
  yield put(addUserAdditions(newAdditions))

  // Handle mutually exclusive annotations
  yield call(
    disableAnnotationCombinationsBySubtype,
    subtype || AnnotationName[annotationType]
  )

  // Allow saving
  yield put(setDataIsChanged(true))
}

export default function* drawingSaga() {
  yield takeEvery(
    [DrawingTypes.SET_DRAWING_ACTION, TeethTypes.SET_ACTIVE_TOOTH],
    setDrawingActionSaga
  )
  yield takeLatest(
    DrawingTypes.TOGGLE_ACTIVE_DRAWING_MODE,
    deactivateMutuallyExclusiveFeatures
  )
  yield takeLatest(
    DrawingTypes.SAVE_DRAWING_ANNOTATION,
    saveDrawingAnnotationSaga
  )
}
