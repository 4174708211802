import { ActionType, getType } from "typesafe-actions"

import * as actions from "library/common/actions/image"

type EntitiesState = Readonly<{
  isProcessed: boolean
  annotationsShown: boolean
  openDateMs: number
  pdfReportData: string
  showImmediately: boolean
}>

const initialState: EntitiesState = {
  isProcessed: false,
  annotationsShown: false,
  openDateMs: Date.now(),
  pdfReportData: "",
  showImmediately: false,
}

type EntitiesActions = ActionType<typeof actions>

export default (
  state = initialState,
  action: EntitiesActions
): EntitiesState => {
  switch (action.type) {
    case getType(actions.loadImageSuccess):
      return { ...state, openDateMs: Date.now() }

    case getType(actions.showAnnotations):
      return { ...state, annotationsShown: action.payload }

    case getType(actions.loadAnnotationsSuccess):
      if (state.showImmediately) {
        return { ...state, isProcessed: true, annotationsShown: true }
      }

      return { ...state, isProcessed: true }

    case getType(actions.setInitialState):
      return { ...initialState, showImmediately: state.showImmediately }

    case getType(actions.setAsNotProcessed):
      return { ...state, isProcessed: false, annotationsShown: false }

    case getType(actions.updateOpenDateMs):
      return { ...state, openDateMs: Date.now() }

    case getType(actions.loadPdfReportSuccess):
      return {
        ...state,
        pdfReportData: action.payload,
      }

    case getType(actions.setShowImmediately):
      return {
        ...state,
        showImmediately: true,
      }

    default:
      return state
  }
}
