import { RootState } from "core/store/configureStore"

export const getFiltersState = ({ filters }: RootState) => filters

export const getShowCaries = ({ filters }: RootState) =>
  filters.showCaries && filters.isEnabled
export const getShowApical = ({ filters }: RootState) =>
  filters.showApical && filters.isEnabled
export const getShowOther = ({ filters }: RootState) =>
  filters.showOther && filters.isEnabled
export const getIsEnabled = ({ filters }: RootState) => filters.isEnabled
export const getIsHighSenseModeActive = (state: RootState) =>
  state.serverData.present.imageMeta.kind === "PERI" ||
  state.serverData.present.imageMeta.kind === "OTHER"
    ? false
    : state.filters.isHighSenseModeActive
export const getActiveNames = ({ filters }: RootState) => ({
  restorations: filters.showOther && filters.isEnabled,
  apical: filters.showApical && filters.isEnabled,
  caries: filters.showCaries && filters.isEnabled,
  calculus: filters.showOther && filters.isEnabled,
  nervus: filters.showOther && filters.isEnabled,
  impacted: filters.showOther && filters.isEnabled,
  annotate: filters.showOther && filters.isEnabled,
})
