import React, { Suspense } from "react"
import { Redirect, Route, Switch } from "react-router-dom"

import { isBrowserValid } from "library/utilities/browser"
import {
  DEFAULT_THEME,
  OAUTH_BASE_URI,
  OAUTH_PROVIDER_NAME,
} from "library/utilities/constants"
import CommonSpinner from "library/common/components/Loaders/CommonSpinner"

const Auth = React.lazy(() => import("pages/Auth"))
const AuthOauth = React.lazy(() => import("pages/AuthOauth"))
const DashboardContainer = React.lazy(
  () => import("pages/Dashboard/DashboardContainer")
)
const AuthSSO = React.lazy(() => import("pages/AuthSSO"))
const ResetPassword = React.lazy(() => import("pages/ResetPassword"))
const IntegratedContainer = React.lazy(
  () => import("pages/Integrated/IntegratedContainer")
)
const ReportContainer = React.lazy(() => import("pages/Report/ReportContainer"))
const Upload = React.lazy(() => import("pages/Upload"))
const XRayHistory = React.lazy(() => import("pages/XRayHistory"))
const UpdateBrowser = React.lazy(() => import("pages/UpdateBrowser"))
const ProtectedLayout = React.lazy(
  () => import("library/common/components/Layouts/ProtectedLayout/")
)

const Routes = () => {
  if (!isBrowserValid) return <UpdateBrowser />

  if (!document.body.className) {
    document.body.className = `theme-${DEFAULT_THEME}`
  }

  return (
    <Suspense fallback={<CommonSpinner />}>
      <Switch>
        <Redirect exact from="/" to="/upload" />
        <Route exact path="/login" component={Auth} />
        {OAUTH_BASE_URI && OAUTH_PROVIDER_NAME && (
          <Route exact path="/login/wso2" component={AuthOauth} />
        )}
        <Route exact path="/sso-login" component={AuthSSO} />
        <Route path="/onboarding" component={ResetPassword} />
        <Route exact path="/" component={DashboardContainer} />
        <Route
          path="/"
          render={({ history, match, location }) => (
            <ProtectedLayout
              history={history}
              match={match}
              location={location}
            >
              <Switch>
                <Route path="/dashboard/:id" component={DashboardContainer} />
                <Route path="/integrated/:id" component={IntegratedContainer} />
                <Route path="/report/:id" component={ReportContainer} />
                <Route
                  path="/report-bone-loss/:id"
                  component={ReportContainer}
                />
                <Route path="/upload" component={Upload} />
                <Route path="/archive" component={XRayHistory} />
                <Redirect to="/upload" />
              </Switch>
            </ProtectedLayout>
          )}
        />
      </Switch>
    </Suspense>
  )
}

export default Routes
