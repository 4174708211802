import localStorage from "library/utilities/localStorage"
import i18n from "i18next"
import { Theme } from "../../library/common/actions/user"

export const supportedLanguages = [
  "en",
  "de",
  "fr",
  "es",
  "pl",
  "it",
  "nl",
  "pt",
]

export const setLanguage = (
  language: string | null,
  setLanguage: (lang: string) => void,
  theme: string
) => {
  const saveLang = (lang: string) => {
    localStorage.setItem("locale", lang)
    void i18n.changeLanguage(lang)
    setLanguage(lang)
  }
  if (
    theme === Theme.carestream &&
    !!language &&
    !supportedLanguages.includes(language)
  ) {
    // for carestream we just set to english as default when we receive an unknown language
    saveLang("en")
  } else if (!!language && supportedLanguages.includes(language)) {
    // for any other case just set the language only if supported and available otherwise do nothing
    saveLang(language)
  }
}
