import {
  call,
  put,
  take,
  fork,
  cancel,
  cancelled,
  takeLatest,
} from "redux-saga/effects"
import { eventChannel, END, Task, EventChannel } from "redux-saga"

import { WebSocketTypes } from "library/common/types/webSocketTypes"

import * as webSocketActions from "library/common/actions/webSocket"
import * as imageActions from "library/common/actions/image"
import { IAnalysisResult, updateImageDataSaga } from "./imageSaga"
import { requestImageAnalysis } from "library/services/imageApis"

function createWebSocketConnection() {
  return new Promise((resolve, reject) => {
    const socket = new WebSocket(process.env.REACT_APP_API_URL_WS!)
    socket.onopen = () => resolve(socket)
    socket.onerror = (evt) => reject(evt)
  })
}

function createSocketChannel(socket: any) {
  return eventChannel((emit) => {
    socket.onmessage = (event: any) => emit(event.data)
    socket.onclose = () => emit(END)
    const unsubscribe = () => (socket.onmessage = null)

    return unsubscribe
  })
}

function* handleMessagesSaga(data: any, socket: WebSocket) {
  yield put(imageActions.imageProcessingComplete())
  socket.close()
}

function* listenForSocketMessages(imageId: string) {
  let socket: WebSocket | undefined
  let socketChannel: EventChannel<null> | undefined

  try {
    socket = yield call(createWebSocketConnection)
    socketChannel = yield call(createSocketChannel, socket)

    console.log("successfully connected to websocket")

    socket?.send(imageId)

    /*
    Check if status changed before websocket connected in order to set all the changed
    values (especially isProcessed and isOwner). Setting isProcessed will avoid infinite
    loading state
    */
    const { data }: IAnalysisResult = yield call(requestImageAnalysis, imageId)
    if (data.status === "done") {
      yield call(updateImageDataSaga, data)
      return
    }

    while (true) {
      const payload: string = yield take(socketChannel as any)
      const parsedPayload = JSON.parse(payload)
      yield fork(handleMessagesSaga as any, parsedPayload, socket)
    }
  } catch (error) {
    console.log("error while connecting to websocket")
  } finally {
    const isCanceled: boolean = yield cancelled()
    if (!isCanceled) return console.log("websocket disconnected")
    socketChannel?.close()
    socket?.close()
  }
}

function* connectSaga({
  payload: imageId,
}: ReturnType<typeof webSocketActions.connect>) {
  const socketTask: Task[] = yield call(listenForSocketMessages, imageId)
  yield cancel(socketTask)
  console.log("successfully disconnected")
}

export default function* entitiesSaga() {
  yield takeLatest(WebSocketTypes.CONNECT, connectSaga)
}
