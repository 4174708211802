import { RootState } from "core/store/configureStore"
import { createSelector } from "reselect"

import { FEATURE_DRAWING_MODE } from "library/utilities/constants"
import { getActiveTooth } from "./teeth"
import { DrawingAction } from "../types/drawing"
import { AnnotationName, RestorationSubtype } from "../types/adjustmentTypes"
import { getAllAdditions, getKind } from "./serverData"
import { getCariesPro } from "./image"

export const drawingActionState = (state: RootState) =>
  state.drawing.drawingAction
export const getDrawingLineThickness = (state: RootState) =>
  state.drawing.drawingLineThickness
export const isDrawing = (state: RootState) => state.drawing.drawing
export const getShowDrawingWarning = (state: RootState) =>
  state.drawing.showDrawingWarning
export const getIsErasing = (state: RootState) => state.drawing.isErasing
export const getActiveCariesProId = (state: RootState) =>
  state.drawing.activeCariesProId

export const getDrawingAction = createSelector(
  [drawingActionState, getActiveTooth, getKind],
  (drawingActionState, activeTooth, kind) =>
    !activeTooth &&
    kind !== "PERI" &&
    drawingActionState !== DrawingAction.annotate
      ? DrawingAction.select
      : drawingActionState
)

export const isDrawingModeActive = (state: RootState) =>
  FEATURE_DRAWING_MODE && state.drawing.drawingModeActive
export const getShowAnnotationDrawingButton = (state: RootState) =>
  FEATURE_DRAWING_MODE && state.drawing.showAnnotationDrawingButton

export const getMasksToDraw = createSelector(
  [
    getAllAdditions,
    getActiveTooth,
    getDrawingAction,
    getActiveCariesProId,
    getCariesPro,
  ],
  (allAdditions, activeTooth, drawingAction, activeCariesProId, cariesPro) => {
    if (drawingAction == DrawingAction.annotate) {
      return allAdditions.filter((a) => a.type === AnnotationName.annotate)
    }
    if (cariesPro && drawingAction == DrawingAction.caries) {
      return allAdditions.filter((a) => a.id === activeCariesProId)
    }

    return allAdditions.filter(
      (a) =>
        a.toothName === activeTooth &&
        ((a.subtype && a.subtype === RestorationSubtype[drawingAction]) ||
          a.type === AnnotationName[drawingAction])
    )
  }
)
