import {
  AnnotationName,
  BonelossAnnotation,
  CariesDepth,
  RestorationSubtype,
} from "./adjustmentTypes"

export enum ServerDataTypes {
  ADD_USER_CHANGES = "@@SERVER_DATA/ADD_USER_CHANGES",
  DELETE_USER_CHANGE = "@@SERVER_DATA/DELETE_USER_CHANGE",
  EDIT_USER_CHANGE = "@@SERVER_DATA/EDIT_USER_CHANGE",
  ADD_USER_ADDITIONS = "@@SERVER_DATA/ADD_USER_ADDITIONS",
  ADD_CARIES_ADDITIONS = "@@SERVER_DATA/ADD_CARIES_ADDITIONS",
  CHANGE_USER_ADDITION = "@@SERVER_DATA/CHANGE_USER_ADDITION",
  DELETE_USER_ADDITION = "@@SERVER_DATA/DELETE_USER_ADDITION",
  DELETE_USER_ADDITION_BY_ID = "@@SERVER_DATA/DELETE_USER_ADDITION_BY_ID",
  DELETE_USER_ADDITION_BY_ID_SUCCESS = "@@SERVER_DATA/DELETE_USER_ADDITION_BY_ID_SUCCESS",
  USER_ADD_ADDED_TEETH = "@@SERVER_DATA/USER_ADD_ADDED_TEETH",
  USER_DELETE_ADDED_TEETH = "@@SERVER_DATA/USER_DELETE_ADDED_TEETH",
  USER_ADD_DELETED_TEETH = "@@SERVER_DATA/USER_ADD_DELETED_TEETH",
  USER_DELETE_DELETED_TEETH = "@@SERVER_DATA/USER_DELETE_DELETED_TEETH",
  SET_MOVED_TEETH = "@@SERVER_DATA/SET_MOVED_TEETH",
  DELETE_MOVED_USER_CHANGE = "@@SERVER_DATA/DELETE_MOVED_USER_CHANGE",
  SET_INITIAL_STATE = "@@SERVER_DATA/SET_INITIAL_STATE",
  SET_GENERAL_COMMENT = "@@SERVER_DATA/SET_GENERAL_COMMENT",
  ADD_ADDED_COMMENTS = "@@SERVER_DATA/ADD_ADDED_COMMENTS",
  SET_TEETH_LOSS = "@@SERVER_DATA/SET_TEETH_LOSS",
  SET_AGE = "@@SERVER_DATA/SET_AGE",
  SET_COMPLEXITY = "@@SERVER_DATA/SET_COMPLEXITY",
  SET_DIABETES = "@@SERVER_DATA/SET_DIABETES",
  SET_SMOKING = "@@SERVER_DATA/SET_SMOKING",
  SET_BONE_LOSS_CATEGORY = "@@SERVER_DATA/SET_BONE_LOSS_CATEGORY",
  SET_BONE_LOSS_INDEX = "@@SERVER_DATA/SET_BONE_LOSS_INDEX",
  SET_TOOTH_BONELOSS = "@@SERVER_DATA/SET_TOOTH_BONELOSS",
  SET_EXTEND_OR_DISTRIBUTION = "@@SERVER_DATA/SET_EXTEND_OR_DISTRIBUTION",
  BONELOSS_INDEX = "@@SERVER_DATA/BONELOSS_INDEX",
  CHANGE_ANNOTATION = "@@SERVER_DATA/CHANGE_ANNOTATION",
  TOGGLE_ANNOTATION = "@@SERVER_DATA/TOGGLE_ANNOTATION",
  FLIP_IMAGE = "@@SERVER_DATA/FLIP_IMAGE",
  TOGGLE_FLIP_IMAGE = "@@SERVER_DATA/TOGGLE_FLIP_IMAGE",
  SAVE_IMAGE_META = "@@SERVER_DATA/SAVE_IMAGE_META",
  SAVE_BONE_LOSS_FORM = "@@SERVER_DATA/SAVE_BONE_LOSS_FORM",
  SAVE_CHANGED_TEETH = "@@SERVER_DATA/SAVE_CHANGED_TEETH",
  MOVE_COMMENTS = "@@SERVER_DATA/MOVE_COMMENT",
  TOGGLE_DISPLAY_HORIZONTALLY_FLIPPED = "@@SERVER_DATA/TOGGLE_DISPLAY_HORIZONTALLY_FLIPPED",
  SET_INFERENCE_STATUS = "@@SERVER_DATA/SET_INFERENCE_STATUS",
  ADJUST_FILTER = "@@SERVER_DATA/ADJUST_FILTER",
}

export interface AnnotationToRender {
  toothName: number
  type: AnnotationName
  subtype?: RestorationSubtype
  shouldMakeActive: boolean // if the checkbox should react to this
  isAddition: boolean // if this is an addition by the user
  // HSM:
  isHSM: boolean // if this is an HSM annotation
  isAcceptedHSM: boolean
  isRejectedHSM: boolean
  isUnconfirmedHSM: boolean // whether HSM was neither `accepted` nor `rejected`
  isEnlargementHSM: boolean // is an unconfirmed HSM, but whether or not enlarges an existing blob/mask
  // it will depend on the logic needed at the moment to be a displayable to the user. Check function parseHsmData()
  isDisplayableHSM: boolean
  // Caries Pro:
  location?: string
  depth?: CariesDepth
  id?: number
  center_mass_x?: number // Only for PERI
  center_mass_y?: number // Only for PERI
  replacing?: number // HSM on PERI: replacing blob with id
  mask?: string | undefined // Drawing mask
}

export type Comment = Readonly<{
  toothName: number
  comment: string
}>

export interface BoneLossFormFull extends BoneLossFormUser {
  stage: string | undefined
  maxBoneLossPercent: number | undefined
  maxBoneLossPercentCategory: string | undefined
  boneLossIndex: string | undefined
  grade: string | undefined
  draft: boolean
}

export interface BoneLossFormUser {
  maxBoneLossPercentCategoryOverride: string | undefined
  boneLossIndexOverride: string | undefined
  toothLoss: string
  complications: string
  distribution: string
  age: number
  diabetes: string
  smoking: string
  changedTeeth: BonelossAnnotation[]
}

// for changing individual Annotations (such as Caries Pro)
export type AnnotationChange = Readonly<{
  id: number
  location?: string
  depth?: CariesDepth
}>

// TODO (martin): These types are only used in the detection list, use something better
export type IAnnotations = Readonly<{
  caries?: AnnotationToRender[]
  apical?: AnnotationToRender[]
  restorations?: AnnotationToRender[]
  calculus?: AnnotationToRender[]
  nervus?: AnnotationToRender[]
  impacted?: AnnotationToRender[]
}>

export type ITooth = Readonly<{
  tooth: number
  isDetected: boolean
  hasComment: boolean
  annotations: IAnnotations | null
}>

export interface IMetaReadonly {
  patientID: boolean
  patientName: boolean
  dateOfBirth: boolean
  imageDate: boolean
}

export interface IMeta {
  angleImageRotation: number | null
  patientID: string
  patientName: string
  dateOfBirth: string
  imageDate: string
  analysisDate: string
  fileName: string
  kind: string
  isImageHorizontallyFlipped: boolean
  displayHorizontallyFlipped: boolean
  readOnlyFields: IMetaReadonly
  imageWidth: number
  imageHeight: number
  isOwner: boolean
}

export interface IMoveComment {
  oldTeeth: number[]
  movedTeeth: number[]
}

export interface IInferenceStatus {
  status: string
  message?: string
}

export type AnnotationRecord = Partial<
  Record<AnnotationName, AnnotationToRender[]>
>

export type ActiveToothAnnotations = Readonly<{
  tooth: number
  annotations: AnnotationRecord
}>

export type MaxBoneLossInfo = Readonly<{
  maxBoneLossPercent: number
  toothName: number | null
}>

export type ImageFilters = Readonly<{
  contrast: number
  saturation: number
  brightness: number
}>

export enum ImageFilterTypes {
  CONTRAST = "contrast",
  SATURATION = "saturation",
  BRIGHTNESS = "brightness",
}
