import {
  call,
  put,
  select,
  takeEvery,
  takeLatest,
} from "@redux-saga/core/effects"
import { SavingTypes } from "../types/savingTypes"
import {
  BoneLossFormFull,
  Comment,
  ServerDataTypes,
} from "../types/serverDataTypes"
import { AnnotationOnTooth } from "../types/adjustmentTypes"
import { UserChange } from "../types/dataStructureTypes"
import teethTypes, { Tooth } from "library/common/types/teethTypes"
import { adjustmentTypes } from "library/common/types/adjustmentTypes"
import { UserTypes } from "../types/userTypes"
import * as imageControlsSelectors from "library/common/selectors/imageControls"
import * as routeSelectors from "library/common/selectors/routes"
import * as savingDataActions from "library/common/actions/saving"

import {
  requestSendChanges,
  requestSendEvent,
  IRequestSendReport,
  IImageManipulation,
} from "library/services/uploadApi"

import {
  getAddedComments,
  getGeneralComment,
  getAllUserChanges,
  getAllRemovedTeeth,
  getAllAddedTeeth,
  getMovedTeeth,
  getAllAdditions,
  getImageMeta,
  getBoneLossFormValues,
  getIsImageHorizontallyFlipped,
  getBrightness,
  getContrast,
  getSaturation,
  getIsOwner,
} from "library/common/selectors/serverData"
import { getCariesPro, getOpenDateMs, getBonelossPro } from "../selectors/image"
import { resetOpenDataMsSaga } from "./imageSaga"
import { IMeta } from "../types/serverDataTypes"
import { notifyWebkitSave } from "library/utilities/integration"

function* requestSendChangesSaga() {
  const generalComment: string = yield select(getGeneralComment)
  const addedComments: Comment[] = yield select(getAddedComments)

  const additions: AnnotationOnTooth[] = yield select(getAllAdditions)

  const changes: UserChange[] = yield select(getAllUserChanges)

  const removedTeeth: Tooth[] = yield select(getAllRemovedTeeth)
  const addedTeeth: Tooth[] = yield select(getAllAddedTeeth)
  const movedTeeth: Record<string, number> = yield select(getMovedTeeth)

  const resultId: string = yield select(routeSelectors.getRouteImageId)

  const cariesPro: boolean | undefined = yield select(getCariesPro)
  const bonelossPro: boolean | null = yield select(getBonelossPro)

  const isImageHorizontallyFlipped: boolean = yield select(
    getIsImageHorizontallyFlipped
  )

  const boneLoss: BoneLossFormFull = yield select(getBoneLossFormValues)

  const meta: IMeta = yield select(getImageMeta)
  const isOwner: boolean = yield select(getIsOwner)

  const data: IRequestSendReport = {
    resultId,
    generalComment,
    addedComments,
    additions,
    addedTeeth,
    changes,
    removedTeeth,
    movedTeeth,
    cariesPro,
    bonelossPro: bonelossPro ?? undefined, // remove it from the request if the value is null
    meta: {
      ...meta,
      isImageHorizontallyFlipped,
    },
    forms:
      bonelossPro !== null
        ? {
            boneLoss: boneLoss,
          }
        : undefined,
  }
  try {
    if (isOwner) {
      yield call(requestSendChanges, data)
    }
    yield put(
      savingDataActions.requestSendChangesComplete({
        success: true,
        id: resultId,
      })
    )
  } catch (error) {
    yield put(
      savingDataActions.requestSendChangesComplete({
        success: false,
        id: resultId,
      })
    )
    console.log(error)
    // TODO (Tim): Show user the error screen.
  }
}

function* requestSendEventSaga({
  payload: button,
}: ReturnType<typeof savingDataActions.requestSendEvent>) {
  const resultId: string = yield select(routeSelectors.getRouteImageId)
  const openDateMs: number = yield select(getOpenDateMs)
  const editorOpenMs = openDateMs ? Date.now() - openDateMs : undefined
  const imageManipulation: IImageManipulation = {
    brightness: yield select(getBrightness),
    contrast: yield select(getContrast),
    saturation: yield select(getSaturation),
  }
  const usedFullscreen: boolean = yield select(
    imageControlsSelectors.getUsedFullscreen
  )

  try {
    yield call(requestSendEvent, {
      action: "close",
      resultId,
      editorOpenMs,
      button,
      imageManipulation,
      usedFullscreen,
    })
  } catch (error) {
    console.log(error)
  }
}

function* requestSendChangesCompleteSaga({
  payload,
}: ReturnType<typeof savingDataActions.requestSendChangesComplete>) {
  if (payload.success) {
    notifyWebkitSave(payload.id)
  }
}

function* allowUserToSaveSaga() {
  yield put(savingDataActions.setDataIsChanged(true))
}

export default function* savingDataSaga() {
  yield takeLatest(SavingTypes.REQUEST_SEND_CHANGES, requestSendChangesSaga)
  yield takeLatest(
    SavingTypes.REQUEST_SEND_CHANGES_COMPLETE,
    requestSendChangesCompleteSaga
  )

  const { CREATE_TOOTH, DELETE_TOOTH } = teethTypes
  const { TOGGLE_CARIES_PRO, TOGGLE_BONELOSS_PRO } = UserTypes
  const { TOGGLE_FLIP_IMAGE } = ServerDataTypes

  yield takeEvery(
    [
      CREATE_TOOTH,
      DELETE_TOOTH,
      adjustmentTypes.TOGGLE_ANNOTATION_ON_TOOTH,
      TOGGLE_CARIES_PRO,
      TOGGLE_FLIP_IMAGE,
      adjustmentTypes.MOVE_ANNOTATION_TO,
      // For cariesPRO annotations
      ServerDataTypes.ADD_CARIES_ADDITIONS,
      ServerDataTypes.DELETE_USER_ADDITION_BY_ID,
      ServerDataTypes.TOGGLE_ANNOTATION,
      ServerDataTypes.CHANGE_ANNOTATION,
      // For system tests
      ServerDataTypes.TOGGLE_DISPLAY_HORIZONTALLY_FLIPPED,
      // For boneloss
      TOGGLE_BONELOSS_PRO,
      ServerDataTypes.SET_TOOTH_BONELOSS,
      ServerDataTypes.SET_TEETH_LOSS,
      ServerDataTypes.SET_BONE_LOSS_CATEGORY,
      ServerDataTypes.SET_BONE_LOSS_INDEX,
      ServerDataTypes.SET_AGE,
      ServerDataTypes.SET_DIABETES,
      ServerDataTypes.SET_SMOKING,
      ServerDataTypes.SET_EXTEND_OR_DISTRIBUTION,
      ServerDataTypes.SET_COMPLEXITY,
    ],
    allowUserToSaveSaga
  ) // one more case is when HSM changes are accepted or rejected but this is found in hsmControlButtons.
  yield takeLatest(SavingTypes.REQUEST_SEND_EVENT, requestSendEventSaga)
  yield takeLatest(SavingTypes.REQUEST_SEND_EVENT, resetOpenDataMsSaga)
}
