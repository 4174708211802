import axios from "axios"
import { store } from "core/store/configureStore"
import {
  logOutAction,
  setServerError,
  setServerErrorMessage,
} from "library/common/actions/user"
import { setShouldTakeScreenshot } from "library/common/actions/upload"

export const baseURL = process.env.REACT_APP_API_URL

const fetch = axios.create({ baseURL })

const hasExpectedData = (data: any) =>
  data &&
  (data.imageWidth ||
    data.imageHeight ||
    data.image ||
    data.newPassword ||
    data.oldPassword)

export const errorHandler = (error: any) => {
  const status = error.response?.status
  if (status === 401 || status === 403) {
    store.dispatch(logOutAction())
  } else if (status === 502 || status === 503) {
    store.dispatch(setServerError("unavailable"))
  } else if (!navigator.onLine || !error.response) {
    store.dispatch(setServerError("disconnect"))
  } else if (
    error.response &&
    (status === 400 || !hasExpectedData(error.response.data)) &&
    !error.response.data.imageHeight &&
    !error.response.data.imageWidth
  ) {
    // show an error popup when the response doesn't contain a type of error we can handle
    store.dispatch(setServerError(`${status}`))
    store.dispatch(setShouldTakeScreenshot(true))
    store.dispatch(setServerErrorMessage(error.response.data?.error))
  }

  return Promise.reject({ ...error })
}
const successHandler = (response: any) => response

fetch.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error)
)

export default fetch
