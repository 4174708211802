import {
  fork,
  takeLatest,
  call,
  put,
  select,
  take,
  takeEvery,
} from "redux-saga/effects"

import * as imageActions from "library/common/actions/image"
import * as entitiesActions from "library/common/actions/entities"
import * as teethActions from "library/common/actions/teeth"
import * as filtersActions from "library/common/actions/filters"
import * as imageControlsActions from "library/common/actions/imageControls"
import * as adjustmentsActions from "library/common/actions/adjustments"
import * as drawingActions from "library/common/actions/drawing"
import * as webSocketActions from "library/common/actions/webSocket"
import * as serverDataActions from "library/common/actions/serverData"
import * as userActions from "library/common/actions/user"

import * as imageAPIs from "library/services/imageApis"
import * as routeSelectors from "library/common/selectors/routes"
import { imageTypes } from "library/common/types/imageTypes"
import { BoneLossFormUser, IMeta } from "library/common/types/serverDataTypes"
import {
  Detection,
  UserChange,
  Boneloss,
  ToothSegment,
  HistoricalResult,
} from "library/common/types/dataStructureTypes"
import {
  AnnotationName,
  AnnotationOnTooth,
  RestorationSubtype,
} from "../types/adjustmentTypes"
import { Tooth } from "../types/teethTypes"
import { Comment } from "../types/serverDataTypes"
import { getLang } from "library/common/selectors/i18n"
import {
  requestSendChanges,
  requestSendChangesComplete,
  setDataIsChanged,
} from "../actions/saving"
import { history } from "core/store/configureStore"
import {
  getShowImmediately,
  getBonelossPro,
  getAnnotationsShown,
} from "../selectors/image"
import { SaveComplete } from "../types/savingTypes"
import { closeModal } from "../actions/modal"
import { getTheme } from "../selectors/user"
import {
  getAllUserChanges,
  getDefaultActiveTooth,
} from "../selectors/serverData"
import { getActiveTooth } from "../selectors/teeth"
import { getEntities } from "../selectors/entities"
import { Entities } from "../types/entitiesTypes"

export interface IAnalysisResult {
  data: IAnalysisData
}

interface IAnalysisData {
  id?: string
  apical?: Detection[]
  boneLoss?: Boneloss
  caries?: Detection[]
  restorations?: Detection[]
  changes?: UserChange[]
  additions?: AnnotationOnTooth[]
  addedComments?: Comment[]
  generalComment?: string
  addedTeeth?: Tooth[]
  removedTeeth?: Tooth[]
  movedTeeth?: Record<string, number>
  teeth?: Tooth[]
  meta?: IMeta
  cariesPro?: boolean
  bonelossPro?: boolean
  status: string
  message?: string
  forms?: { boneLoss: BoneLossFormUser }
  calculus?: Detection[]
  nervus?: Detection[]
  segments?: ToothSegment[]
  impacted?: Detection[]
  historicalResults?: HistoricalResult[]
}

function* cleanCachedImageSaga(skip: any) {
  yield put(entitiesActions.setInitialState())
  yield put(teethActions.setInitialState())
  if (!skip.image) yield put(imageActions.setInitialState())
  yield put(imageActions.setAsNotProcessed())
  yield put(filtersActions.setInitialState())
  yield put(imageControlsActions.setInitialState())
  yield put(adjustmentsActions.setInitialState())
  yield put(serverDataActions.setInitialState())
  yield put(drawingActions.setInitialState())
}

// reject boneLoss that doesn't fit our expected format
const cleanBoneLoss = (boneLoss: any) =>
  boneLoss?.annotations && boneLoss?.mask ? boneLoss : undefined

// reject nervus that doesn't fit our expected format
const cleanNervus = (nervus: any) =>
  nervus && nervus[0]?.annotations ? [] : nervus

export function* updateImageDataSaga(data: IAnalysisData) {
  yield put(
    entitiesActions.saveAnnotations({
      apical: data.apical || [],
      boneloss: cleanBoneLoss(data.boneLoss),
      caries: data.caries || [],
      restorations: data.restorations || [],
      detectedTeeth: data.teeth || [],
      calculus: data.calculus || [],
      nervus: cleanNervus(data.nervus) || [],
      segments: data.segments || [],
      impacted: data.impacted || [],
      historicalResults: data.historicalResults || [],
    })
  )
  yield put(entitiesActions.setImageId(data.id || ""))
  yield put(serverDataActions.addUserAdditions(data.additions || []))
  yield put(serverDataActions.addUserChanges(data.changes || []))
  yield put(serverDataActions.userAddAddedTeeth(data.addedTeeth || []))
  yield put(serverDataActions.userAddDeletedTeeth(data.removedTeeth || []))
  yield put(serverDataActions.addAddedComments(data.addedComments || []))
  yield put(serverDataActions.setGeneralComment(data.generalComment || ""))
  yield put(serverDataActions.setMovedTeeth(data.movedTeeth || {}))
  if (data.forms) {
    yield put(serverDataActions.saveBoneLossForm(data.forms.boneLoss))
  }

  if (data.meta) {
    const meta = {
      ...data.meta,
      isImageHorizontallyFlipped: !!data.meta.isImageHorizontallyFlipped,
      angleImageRotation: data.meta.angleImageRotation || 0,
    }
    yield put(serverDataActions.saveImageMeta(meta))
  }
  const { cariesPro, bonelossPro, status, message } = data
  yield put(userActions.setUserInfo({ bonelossPro, cariesPro }))
  if (status === "done" || status === "error") {
    const inferenceStatus = {
      status,
      message,
    }
    yield put(serverDataActions.setInferenceStatus(inferenceStatus))
    yield put(imageActions.loadAnnotationsSuccess())
  } else if (data.id) {
    yield put(webSocketActions.connect(data.id))
  }
}

function* loadAnnotationsSaga() {
  try {
    const id: string = yield select(routeSelectors.getRouteImageId)
    if (id) {
      const { data }: IAnalysisResult = yield call(
        imageAPIs.requestImageAnalysis,
        id
      )
      yield call(updateImageDataSaga, data)
      const showImmediately: boolean = yield select(getShowImmediately)
      if (showImmediately) {
        yield put(imageActions.showAnnotations(data.status !== "error"))
      }
    }
  } catch (error) {
    console.error(error)
  }

  const entities: Entities = yield select(getEntities)
  const userChanges: UserChange[] = yield select(getAllUserChanges)
  const changedIds = userChanges.flatMap((u) => u.annotationId)

  const keys = [
    AnnotationName.apical,
    AnnotationName.calculus,
    AnnotationName.caries,
    AnnotationName.nervus,
    AnnotationName.restorations,
    AnnotationName.impacted,
  ]

  // Only work with applicable entities
  const filteredEntities = keys.flatMap((k) => entities[k] || [])
  const changedTeeth = filteredEntities.flatMap(
    (f) => (changedIds.includes(f.id) && f.toothName) || []
  )

  const uniqueChangedTeeth = new Set(changedTeeth)

  // Extract tooth names where a certain restoration appears
  const getAffectedTeeth = (subtype: RestorationSubtype) =>
    entities.restorations
      .filter((e) => e.subtype === subtype)
      .map((e) => e.toothName)

  const filteredBridges = getAffectedTeeth(RestorationSubtype.bridges)
  const filteredImplants = getAffectedTeeth(RestorationSubtype.implants)

  const rejectedIds = filteredEntities
    .filter(
      (f) =>
        !uniqueChangedTeeth.has(f.toothName) &&
        ((f.subtype !== RestorationSubtype.bridges &&
          filteredBridges.includes(f.toothName)) ||
          (f.subtype !== RestorationSubtype.bridges &&
            f.subtype !== RestorationSubtype.crowns &&
            f.subtype !== RestorationSubtype.implants &&
            filteredImplants.includes(f.toothName)))
    )
    .map((entity) => entity.id)

  yield put(
    serverDataActions.addUserChanges(
      rejectedIds.map((detectionId) => ({
        action: "rejected",
        annotationId: detectionId,
      }))
    )
  )
}

// Reset the local data and send the reanalysis request to the server
export function* reanalyseImage(payload: imageAPIs.ReanalyzeRequest) {
  yield call(cleanCachedImageSaga, { image: true })
  try {
    const { data }: IAnalysisResult = yield call(
      imageAPIs.requestReanalyze,
      payload
    )
    yield fork(updateImageDataSaga, data)
    yield put(webSocketActions.connect(payload.id))
  } catch (error) {
    console.error(error)
  }
}

// Reset the analysis
export function* reanalyseImageSaga() {
  const id: string = yield select(routeSelectors.getRouteImageId)
  const annotationsShown: boolean = yield select(getAnnotationsShown)

  yield put(requestSendChanges())
  const { payload }: { payload: SaveComplete } = yield take(
    requestSendChangesComplete
  )

  if (payload.success) {
    yield call(reanalyseImage, { id })
  }

  if (annotationsShown) {
    yield put(imageActions.showAnnotations(true))
  }
  yield put(closeModal())
}

// Reset the analysis and rotate 180 degrees
function* rotateImageSaga() {
  const id: string = yield select(routeSelectors.getRouteImageId)
  yield call(reanalyseImage, {
    id,
    rotate: 180,
  })
  yield put(closeModal())
}

// Reset the analysis and change the image kind
function* changeRadiographTypeSaga({
  payload: kind,
}: ReturnType<typeof imageActions.changeRadiographType>) {
  const id: string = yield select(routeSelectors.getRouteImageId)
  yield call(reanalyseImage, { id, kind })
}

function* loadImageSaga() {
  yield call(cleanCachedImageSaga, {})
  yield put(imageActions.loadImageSuccess())
}

export function* resetOpenDataMsSaga() {
  yield put(imageActions.updateOpenDateMs())
}

export function* loadPdfReportSaga({
  payload: PdfReport,
}: ReturnType<typeof imageActions.loadPdfReport>) {
  const lang: string = yield select(getLang)
  const theme: userActions.Theme = yield select(getTheme)
  const boneLossPro: boolean | null = yield select(getBonelossPro)
  try {
    const pdfData: object = yield call(
      boneLossPro || PdfReport.isBoneLossPdf // Bone loss exists and is toggled on or url contains report-bone-loss
        ? imageAPIs.requestBoneLossPdfReport
        : imageAPIs.requestPdfReport,
      PdfReport.id,
      lang,
      theme
    )
    if (!pdfData["data"].pdf) {
      throw Error("PDF Report could not be loaded, empty pdf key in response.")
    }
    yield put(imageActions.loadPdfReportSuccess(pdfData["data"].pdf))
  } catch (error) {
    console.error(error)
  }
}

export function* openPdfReportSaga() {
  yield put(setDataIsChanged(false)) // Prevent save alert to pop up.
  yield put(requestSendChanges())
  const { payload }: { payload: SaveComplete } = yield take(
    requestSendChangesComplete
  )
  const boneLossPro: boolean | null = yield select(getBonelossPro)
  if (payload.success) {
    boneLossPro
      ? history.push(`/report-bone-loss/${payload.id}`)
      : history.push(`/report/${payload.id}`)
  }
}

export function* ensureActiveToothExists() {
  const showAnnotations: boolean = yield select(getAnnotationsShown)
  const defaultActiveTooth: number | null = yield select(getDefaultActiveTooth)
  const activeTooth: number | null = yield select(getActiveTooth)
  if (showAnnotations && defaultActiveTooth && !activeTooth) {
    yield put(teethActions.setActiveTooth(defaultActiveTooth))
  }
}

export function* revertImageSaga({
  payload: id,
}: ReturnType<typeof imageActions.revertImage>) {
  const resultId: string = yield select(routeSelectors.getRouteImageId)
  const annotationsShown: boolean = yield select(getAnnotationsShown)

  try {
    yield call(imageAPIs.revertImage, resultId, id)
    yield call(cleanCachedImageSaga, {})
    yield put(imageActions.loadImageSuccess())
    if (annotationsShown) {
      yield put(imageActions.showAnnotations(true))
    }
  } catch (error) {
    console.error(error)
  }
}

export default function* entitiesSaga() {
  yield takeLatest(imageTypes.LOAD_IMAGE, loadImageSaga)
  yield takeLatest(imageTypes.ROTATE_IMAGE, rotateImageSaga)
  yield takeEvery(
    [imageTypes.IMAGE_PROCESSING_COMPLETE, imageTypes.LOAD_IMAGE_SUCCESS],
    loadAnnotationsSaga
  )
  yield takeLatest(imageTypes.CHANGE_RADIOGRAPH_TYPE, changeRadiographTypeSaga)
  yield takeLatest(imageTypes.REVERT_IMAGE, revertImageSaga)
  yield takeLatest(imageTypes.SHOW_ANNOTATIONS, resetOpenDataMsSaga)
  yield takeLatest(imageTypes.LOAD_PDF_REPORT, loadPdfReportSaga)
  yield takeLatest(imageTypes.OPEN_PDF_REPORT, openPdfReportSaga)
  yield takeEvery(imageTypes.REANALYZE_IMAGE, reanalyseImageSaga)
  yield takeEvery(
    [imageTypes.SHOW_ANNOTATIONS, imageTypes.LOAD_ANNOTATIONS_SUCCESS],
    ensureActiveToothExists
  )
}
