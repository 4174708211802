import { ActionType, getType } from "typesafe-actions"

import * as actions from "../actions/drawing"
import { AnnotationOnTooth } from "../types/adjustmentTypes"
import { DrawingAction, DrawingLineThickness } from "../types/drawing"

type DrawingState = Readonly<{
  drawingAction: DrawingAction
  drawingModeActive: boolean
  showAnnotationDrawingButton: boolean
  drawingLineThickness: DrawingLineThickness
  drawing: boolean
  showDrawingWarning: boolean
  isErasing: boolean
  activeCariesProId: number | null
  activeAddition: AnnotationOnTooth | null
}>

const initialState: DrawingState = {
  drawingAction: DrawingAction.select,
  drawingModeActive: false,
  showAnnotationDrawingButton: false,
  drawingLineThickness: DrawingLineThickness.medium,
  drawing: false,
  showDrawingWarning: false,
  isErasing: false,
  activeCariesProId: null,
  activeAddition: null,
}

type DrawingActions = ActionType<typeof actions>

export default (state = initialState, action: DrawingActions): DrawingState => {
  switch (action.type) {
    case getType(actions.setInitialState): {
      return { ...initialState }
    }
    case getType(actions.setDrawingAction):
      return { ...state, drawingAction: action.payload }
    case getType(actions.toggleActiveDrawingMode):
      return {
        ...state,
        drawingModeActive: !state.drawingModeActive,
        drawingAction: state.drawingModeActive
          ? DrawingAction.select
          : state.drawingAction,
      }
    case getType(actions.setShowAnnotationDrawingButton):
      return { ...state, showAnnotationDrawingButton: action.payload }
    case getType(actions.setDrawingLineThickness):
      return { ...state, drawingLineThickness: action.payload }
    case getType(actions.setDrawing):
      return { ...state, drawing: action.payload }
    case getType(actions.setShowDrawingWarning):
      return { ...state, showDrawingWarning: action.payload }
    case getType(actions.setIsErasing):
      return { ...state, isErasing: action.payload }
    case getType(actions.setActiveCariesProId):
      return { ...state, activeCariesProId: action.payload }
    case getType(actions.setActiveAddition):
      return {
        ...state,
        activeAddition: action.payload
          ? { ...state.activeAddition, ...action.payload }
          : null,
      }

    default:
      return state
  }
}
