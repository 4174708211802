import { ActionType, getType } from "typesafe-actions"

import * as actions from "library/common/actions/entities"
import { Entities } from "../types/entitiesTypes"

const initialState: Entities = {
  apical: [],
  boneloss: undefined,
  caries: [],
  restorations: [],
  detectedTeeth: [],
  imageId: "",
  calculus: [],
  nervus: [],
  segments: [],
  impacted: [],
  historicalResults: [],
}

type EntitiesActions = ActionType<typeof actions>

export default (state = initialState, action: EntitiesActions): Entities => {
  switch (action.type) {
    case getType(actions.saveAnnotations):
      return { ...state, ...action.payload }

    case getType(actions.setInitialState):
      return { ...initialState }

    case getType(actions.setImageId):
      return { ...state, imageId: action.payload }
    case getType(actions.addDetectedTeeth):
      return {
        ...state,
        detectedTeeth: [...state.detectedTeeth, ...action.payload],
      }
    case getType(actions.deleteDetectedTooth):
      return {
        ...state,
        detectedTeeth: state.detectedTeeth.filter(
          (tooth) => tooth.toothName !== action.payload.toothName
        ),
      }
    default:
      return state
  }
}
